.loading {
    text-align: center;
    padding-top: 200px;
    font-size: 50px;
    color: #a358df;
}

.timelineName {
    position: absolute;
    left: 360px;
    top: 19px;
    right: 20px;
}
