.iconButton {
    font-size: 1em;
    width: 2.5em;
    height: 2.5em;
    cursor: pointer;
    border: none;
    background: none;
    background-color: #fff;
    border-radius: 5em;
    border: 1px solid #a258df4f;
    display: inline-flex;
    justify-content: center;
    align-items: center;

    &:hover {
        background-color: #e0cdf0;
    }

    &:active {
        background-color: #d4aef3;
    }

    &:focus {
        outline: 0;
    }
}
