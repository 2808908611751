.container {
    position: absolute;
    z-index: var(--item-drop-preview-z-index);
    display: flex;
    align-items: center;
}

.item {
    padding: 2px;
    height: 32px;
    box-sizing: border-box;
    position: relative;
    opacity: 0.4;
}

.itemInnerWhite {
    border: 2px solid #fff;
    border-radius: 4px;
    height: 100%;
    box-sizing: border-box;
}

.itemInnerColor {
    border: 2px dotted rgb(183, 0, 255);
    border-radius: 4px;
    position: absolute;
    top: 2px;
    right: 2px;
    bottom: 2px;
    left: 2px;
}
