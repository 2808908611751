.columns {
    display: flex;
    justify-content: stretch;
    overflow: hidden;

    @media screen and (max-width: 500px) {
        width: 100% !important;
        flex-direction: column-reverse;

        .column {
            width: 100% !important;
            padding: 30px !important;

            &:nth-of-type(2) {
                height: 70vw;
            }
        }
    }
}

.column {
    padding: 50px 100px 50px 50px;
    flex-grow: 1;

    &:nth-of-type(1) {
        width: 60%;
        padding-top: 100px;
        padding-bottom: 100px;
    }

    &:nth-of-type(2) {
        width: 40%;
        position: relative;
    }
}

h1 {
    font-size: 4rem;
    color: #8d23e4;
}

p {
    color: rgb(58, 58, 58);
    font-size: 1.2rem;
    margin-top: 1rem;
}

.createButton {
    margin-top: 30px;
}

.img1,
.img2 {
    width: 100%;
    height: 50%;
    background-size: cover;
    background-repeat: no-repeat;
    box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.24), 0 1px 20px 0 rgba(0, 0, 0, 0.06);
    position: absolute;
}

.img1 {
    background-image: url(./main/img1.png);
    transform: rotate(-7deg);
    top: 10%;
    left: 0%;
}

.img2 {
    background-image: url(./main/img2.png);
    transform: rotate(-9deg);
    top: 35%;
    left: -10%;
}
