.backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    overflow-y: auto;
    box-shadow: inset 0 0 20px -6px #000;
    z-index: var(--modals-layer-z-index);
}

.content {
    padding-top: 70px;
    padding-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}
