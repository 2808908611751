.itemWrapper {
    position: fixed;
    z-index: var(--item-drag-preview-z-index);
    box-sizing: border-box;
    height: 32px;
    padding: 2px;
}

.item {
    background-color: #a358df;
    height: 28px;
    border-radius: 4px;
    color: #fff;
    font-family: helvetica;
    overflow: hidden;
    cursor: move;
    box-shadow: 0 0 5px 0 #000;
    position: absolute;
    opacity: 0.85;
}

.titleContainerWrapper {
    height: 100%;
    width: 100%;
}

.titleContainer {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
}

.title {
    color: #fff;
    white-space: nowrap;
    overflow: hidden;
    padding: 0 10px;
    font-size: 14px;
}
