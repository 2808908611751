.topbar {
    height: 80px;
    font-size: 30px;
    color: #fff;
    padding-right: 20px;
    overflow: hidden;
    position: relative;
}

.homeLink {
    padding: 0 30px 0 50px;
    height: 100%;
    display: inline-flex;
    align-items: center;
    text-decoration: none !important;
    color: #fff !important;
}

.logo {
    width: 30px;
    margin-right: 20px;
}

.content {
    position: absolute;
    top: 80px;
    left: 0;
    right: 0;
    bottom: 50px;
    overflow: auto;
    z-index: var(--timeline-z-index);
}

.contentBackground {
    background-color: #fff;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.466);
}

.footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 50px;
    display: flex;
    text-align: center;
    justify-content: center;
    flex-direction: column;
}

.copyright {
    text-align: center;
    font-size: 10px;
    color: rgba(255, 255, 255, 0.472);
}

.links {
    text-align: center;
}

.footerLink {
    display: inline-block;
    font-size: 10px;
    color: rgba(255, 255, 255, 0.472);

    & + & {
        margin-left: 10px;
        padding-left: 10px;
        border-left: 1px solid rgba(255, 255, 255, 0.472);
    }
}

.footerLinkInner {
    cursor: pointer;

    &:hover {
        color: rgba(255, 255, 255, 0.664);
    }
}
