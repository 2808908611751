.DateRangePickerInput_arrow {
    margin-left: 18px;

    &_svg {
        fill: var(--control-check-color);
    }
}

.DateInput {
    width: 140px;

    & ~ & {
        margin-left: 20px;

        &:before {
            content: "to";
        }
        &:after {
            content: "to";
        }
    }

    &:before {
        content: "from";
        position: absolute;
        top: 0;
        left: 7px;
        height: 1px;
        font-size: 10px;
        background-color: white;
        overflow: hidden;
        padding: 0 5px;
    }
    &:after {
        content: "from";
        position: absolute;
        top: -5px;
        left: 7px;
        font-size: 10px;
        padding: 0 5px;
    }

    &_input {
        border: 1px solid var(--control-border-color);
        border-radius: 5px;
        overflow: hidden;

        &:hover {
            border-color: var(--control-border-color-hovered);
            background-color: var(--control-background-color-hovered);
        }

        &__focused {
            border-color: var(--control-border-color-focused) !important;
            background-color: var(--control-background-color-focused) !important;
        }
    }
}

.DayPickerKeyboardShortcuts_show {
    display: none;
}
