.linkButton {
    padding: 0.4em;
    opacity: 0.6;
    cursor: pointer;
    border: none;
    background: none;
    font-size: 14px;

    &:hover {
        opacity: 0.8;
    }

    &:active {
        opacity: 1;
    }

    &:focus {
        outline: 0;
    }
}
