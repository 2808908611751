:root {
    --color: rgb(255, 102, 102);
}

.timeIndicator {
    position: absolute;
    top: 4px;
    left: 0;
    width: 2px;
    background-color: var(--color);
    border-radius: 2px;
    transform: translateX(-50%);
}

.dot {
    width: 6px;
    height: 6px;
    border-radius: 100%;
    background-color: var(--color);
    position: absolute;
    left: 50%;
}

.dotTop {
    top: 0;
    transform: translateX(-50%) translateY(-50%);
}

.dotBottom {
    bottom: 0;
    transform: translateX(-50%) translateY(50%);
}
