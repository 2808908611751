:root {
    /**
     * Z-indexes.
     */
    --timeline-z-index: 5;
    --item-active-z-index: 10;
    --item-drop-preview-z-index: 20;
    --item-drag-preview-z-index: 30;
    --modals-layer-z-index: 1000;
}
