:root {
    /**
     * Controls.
     */
    --control-border-color: #dbdbdb;
    --control-border-color-hovered: #a258dd;
    --control-border-color-focused: #a258dd;
    --control-border-width: 1px;
    --control-border-radius: 5px;
    --control-background-color: white;
    --control-background-color-hovered: white;
    --control-background-color-focused: rgb(253, 246, 253);
    --control-check-color: #a258dd;
}
