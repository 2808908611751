.itemWrapper {
    position: absolute;
    z-index: 1;
    box-sizing: border-box;
    height: 32px;
    padding: 2px;
}

.itemWrapperDragging {
    opacity: 0;
}
.itemWrapperResizing .resizer {
    display: flex;
}
.itemWrapperRemoving .item {
    background-color: #df586a;
}
.itemWrapperRemoving .resizer {
    background-color: #d4152f;
}
.itemWrapper:hover,
.itemWrapperEditing,
.itemWrapperResizing {
    z-index: var(--item-active-z-index);
}
.itemWrapperEditing .item,
.itemWrapperResizing .item {
    box-shadow: 0 0 5px 0 #000;
}

.item {
    background-color: #a358df;
    height: 28px;
    border-radius: 4px;
    color: #fff;
    font-family: helvetica;
    overflow: hidden;
    cursor: move;
}

.titleContainerWrapper {
    height: 100%;
    width: 100%;
}

.titleContainer {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
}

.item:hover .resizer {
    display: block;
}

.resizer {
    background-color: #762db3;
    display: none;
    position: absolute;
    top: 2px;
    right: 2px;
    height: 28px;
    border-radius: 0 4px 4px 0;
    cursor: ew-resize;
}

.resizerInner {
    display: flex;
    align-items: center;
    padding: 0 3px;
    font-size: 12px;
    width: 100%;
    height: 100%;
}

.input {
    background: none;
    border: none;
    color: #fff;
    height: 100%;
    width: 100%;
    padding: 0 10px;
    font-size: 14px;
    outline: none;
}

.title {
    color: #fff;
    white-space: nowrap;
    overflow: hidden;
    padding: 0 10px;
    font-size: 14px;
}
