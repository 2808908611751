$placeholderInputTextColor: rgba(160, 160, 160, 0.3);
$placeholderDisplayTextColor: rgb(160, 160, 160);

.wrapper {
    cursor: text;
    display: flex;
    align-items: center;

    &:hover .editIcon {
        opacity: 1;
        transition-delay: 200ms;
        transition-duration: 600ms;
    }
}

.root {
    flex: 1 0 auto;
    width: 100%;
    box-sizing: border-box;
    border: 0.065em dashed transparent;
    border-radius: 0.1em;
    padding: 0.05em 0.3em;
    transition-property: border;
    transition-duration: 300ms;

    &.editing {
        border-color: #999;
    }
}

.editIcon {
    opacity: 0;
    color: #999;
    font-size: 0.6em;
    position: absolute;
    transform: translateY(0.4em);
    margin-left: 0.8em;
    display: inline;
    transition-property: opacity;
    transition-duration: 200ms;
}

.editIconFixed {
    right: 0;
}

.inputBox {
    border: none;
    height: 100%;
    width: 100%;
    font-size: inherit;
    font-family: inherit;
    color: inherit;
    outline: none;
    background: none;

    &::-webkit-input-placeholder {
        color: $placeholderInputTextColor;
    }
    &::-moz-placeholder {
        color: $placeholderInputTextColor;
    }
    &:-ms-input-placeholder {
        color: $placeholderInputTextColor;
    }
    &:-moz-placeholder {
        color: $placeholderInputTextColor;
    }
    &::placeholder {
        color: $placeholderInputTextColor;
    }
}

.displayBox {
    white-space: nowrap;
    position: relative;
    padding-right: 0.7em;

    &.placeholder {
        color: $placeholderDisplayTextColor;
    }
}
