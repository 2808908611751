.form {
}

.formRow {
    text-align: center;

    & + & {
        margin-top: 20px;
    }
}

.error {
    color: red;
}

.precisionOptions {
    display: flex;
    justify-content: center;
}

.precisionOption {
    & + & {
        margin-left: 20px;
    }
}

.buttons {
    margin: 40px 0 10px !important;
}
