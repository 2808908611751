$rowHeaderWidth: 200px;
$cellWidth: 120px;

.timeline {
    position: relative;
    width: 100%;
    height: 100%;
}

.topLeftCornerCover {
    position: absolute;
    top: 0;
    left: 0;
    background-color: white;
    border: 1px solid #f1f1f1;
    z-index: 102;
    width: 200px;
    height: 60px;
}

.dragLayerContainer {
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
}

.timeIndicatorLayerContainer {
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
}

.tableWrapper {
    width: 100%;
    height: 100%;
    overflow: auto;
}

.table {
    table-layout: fixed;
    border-collapse: collapse;
    background-color: #fff;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.466);

    tr {
        height: 60px;
    }

    th:first-child {
        min-width: $rowHeaderWidth;
        width: $rowHeaderWidth;
        max-width: $rowHeaderWidth;
    }

    thead th {
        top: 0;
    }

    th {
        position: -webkit-sticky;
        position: sticky;
        background-color: white;
    }

    th[scope="row"] {
        position: -webkit-sticky;
        position: sticky;
        left: 0;
        z-index: 1;
    }

    thead {
        th {
            &:not(&:first-child) {
                z-index: 100;
                min-width: $cellWidth;
                width: $cellWidth;
                max-width: $cellWidth;

                & > * {
                    width: 121px;
                    height: 60px;
                    border: 1px solid #f1f1f1;
                    border-left: none;
                }
            }
        }
    }

    tbody {
        th {
            &:first-child {
                z-index: 101;
                text-align: left;

                & > * {
                    width: 100%;
                    height: 60px;
                    border-right: 1px solid #f1f1f1;
                }
            }
        }

        tr {
            &:last-child {
                border-bottom: 1px solid #f1f1f1;
            }

            &:hover {
                th {
                    background-color: #eceeff;
                }
            }

            th {
                transition: background 250ms;
            }

            td {
                min-width: $cellWidth;
                width: $cellWidth;
                max-width: $cellWidth;
                border-right: 1px solid #f1f1f1;
            }
        }
    }
}
