.firstTableRowCell {
    position: relative;
}

.tableRow {
    transition: background 250ms;
}

.tableRow:hover {
    background: rgba(209, 212, 250, 0.4);
}

.titleContainer {
    box-sizing: border-box;
    width: 200px;
    font-weight: 600;
    position: relative;

    &:hover {
        .titleEditableTextWrapper {
            padding-right: 40px;
        }

        .titleEditButton,
        .groupRemoveButton,
        .groupItemAddButton {
            opacity: initial;
        }
    }
}

.titleEditableTextWrapper {
    height: 100%;
    padding: 0 10px;
    font-size: 14px;
}

.actionButtons {
    position: absolute;
    display: flex;
    align-items: center;
    right: 0;
    top: 0;
    bottom: 0;
    padding-right: 10px;
}

.titleEditButton,
.groupRemoveButton {
    font-size: 10px;
}

.titleEditButton,
.groupRemoveButton,
.groupItemAddButton {
    opacity: 0;
    transition: opacity linear 100ms;
}

.groupRemoveButton {
    margin-left: 0.5em;
}

.titleInput {
    width: 100%;
    outline: none;
    border: none;
    font-size: 14px;
    line-height: 22px;
}

.items {
    height: 60px;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
}

.newGroupTitleContainer {
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 10px;
}
