.content p + p {
    margin-top: 5px;
}

.contentImage {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
}

.imageWithPointer {
    position: relative;
    max-width: 80%;
    box-shadow: 0 0 20px -6px rgba(0, 0, 0, 0.52);
}

.image {
    max-width: 100%;
}

.footer {
    text-align: center;
    font-size: 20px;
}

.buttonDone {
    color: rgb(6, 161, 6);
}
