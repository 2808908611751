.button {
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.05em;
    height: 3em;
    padding: 0 1.5em;
    cursor: pointer;
    border: none;
    background: none;
    background-color: #9237dd;
    color: white;
    border-radius: 0.4em;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);

    & + & {
        margin-left: 10px;
    }

    &.secondary {
        background-color: white;
        color: black;
    }

    &:hover {
        background-color: #a55de0;

        &.secondary {
            background-color: rgb(236, 236, 236);
        }
    }

    &:active {
        box-shadow: none;
        background-color: #a14fe4;

        &.secondary {
            background-color: rgb(221, 221, 221);
        }
    }

    &:focus {
        outline: 0;
    }

    &:disabled {
        background-color: #c19be0 !important;

        &.secondary {
            background-color: rgb(241, 241, 241) !important;
        }
    }
}

.icon + .text {
    margin-left: 10px;
}
