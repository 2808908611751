.container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
}

.container:hover .newItem {
    opacity: 1;
    transition: opacity 0.7s ease-in-out;
}

.newItem {
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
    position: relative;
    height: 32px;
    padding: 2px;
    box-sizing: border-box;
}

.newItemInner {
    background-color: #a358df;
    opacity: 0.1;
    height: 28px;
    font-size: 14px;
    border-radius: 4px;
    color: #fff;
    font-family: helvetica;
    overflow: hidden;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}
