.pointer {
    position: absolute;
}

.pulse {
    animation: pulse 2s infinite;
}

@keyframes pulse {
    0% {
        transform: scale(1);
        filter: drop-shadow(0 0 10px rgba(172, 55, 218, 0.9));
    }

    80% {
        transform: scale(1.4);
        filter: drop-shadow(0 0 0 rgba(0, 0, 0, 0));
    }

    100% {
        transform: scale(1.2);
        filter: drop-shadow(0 0 0 rgba(0, 0, 0, 0));
    }
}
