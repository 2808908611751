.headerCell {
    height: 50px;
}

.headerCellContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-weight: 200;
}

.headerCellDayOfWeek {
    font-weight: 500;
}
.headerCellDayOfMonth {
    font-weight: 100;
    font-size: 12px;
}

.headerCellWeekText {
    font-weight: 200;
}
.headerCellWeekNumber {
    font-weight: 500;
}
.headerCellWeekRange {
    font-weight: 100;
    font-size: 12px;
}
