.radio {
    [type="radio"] {
        border: var(--control-border-width) solid var(--control-border-color);
    }

    /* "hiding" actual control */
    [type="radio"]:not(:checked),
    [type="radio"]:checked {
        position: absolute;
        left: -9999px;
    }

    /* label */
    [type="radio"]:not(:checked) + label,
    [type="radio"]:checked + label {
        position: relative;
        padding-left: 1.95em;
        cursor: pointer;
    }

    /* outer circle */
    [type="radio"]:not(:checked) + label:before,
    [type="radio"]:checked + label:before {
        content: "";
        position: absolute;
        left: 0;
        top: -0.125em;
        width: 1.25em;
        height: 1.25em;
        border: var(--control-border-width) solid var(--control-border-color);
        background-color: var(--control-background-color);
        border-radius: 1em;
    }

    /* inner circle */
    [type="radio"]:not(:checked) + label:after,
    [type="radio"]:checked + label:after {
        content: " ";
        display: inline-flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        left: 0;
        top: -0.125em;
        width: 1.25em;
        height: 1.25em;
        border: var(--control-border-width) solid transparent;
        line-height: 0.8;
        color: var(--control-check-color);
        transition: all 0.2s;
        font-family: "Lucida Sans Unicode", "Arial Unicode MS", Arial;
        background-color: var(--control-check-color);
        border-radius: 1em;
    }

    /* inner circle - (animation) not checked */
    [type="radio"]:not(:checked) + label:after {
        opacity: 0;
        transform: scale(0);
    }

    /* inner circle - (animation) checked */
    [type="radio"]:checked + label:after {
        opacity: 1;
        transform: scale(0.4);
    }

    /* disabled */
    [type="radio"]:disabled:not(:checked) + label:before,
    [type="radio"]:disabled:checked + label:before {
        box-shadow: none;
        border-color: #bbb;
        background-color: #ddd;
    }
    [type="radio"]:disabled:checked + label:after {
        color: #999;
    }
    [type="radio"]:disabled + label {
        color: #aaa;
    }

    /* focus & active */
    [type="radio"]:checked:focus + label:before,
    [type="radio"]:checked:active + label:before,
    [type="radio"]:not(:checked):focus + label:before,
    [type="radio"]:not(:checked):active + label:before {
        border: var(--control-border-width) solid var(--control-border-color-focused);
        background-color: var(--control-background-color-focused) !important;
    }

    /* hover */
    label:hover:before {
        border: var(--control-border-width) solid var(--control-border-color-hovered) !important;
        background-color: var(--control-background-color-hovered) !important;
    }
}
